import React from "react";
import Image from "gatsby-image";
import PropTypes from "prop-types";
import removeTagsInString from "../helpers/removeTagsInString";

const Articulo = ({ post }) => {
  const {
    featuredImage: {
      node: {
        localFile: { childImageSharp: image },
      },
    },
    categories: { nodes: categories },
    title,
    excerpt,
    link,
  } = post;

  return (
    <>
      <div className="p-4 md:w-1/3 md:mb-0 mb-6 flex flex-col justify-start items-center max-w-sm mx-auto  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-104">
        <Image
          className="bg-gray-300 h-56 w-full rounded-lg shadow-md bg-cover bg-center"
          fluid={image.fluid}
        />

        <div className="w-11/12 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden p-5 z-10">
          {categories.map((category) => {
            if (category.name !== "Destacada")
              return (
                <div
                  key={category.id}
                  className="inline-flex mb-2 items-center"
                >
                  <div className="h-4 w-4 rounded-full ml-2 bg-purple-100 flex items-center">
                    <div className="h-2 w-2 rounded-full bg-blue-500"></div>
                  </div>
                  <div className="category-title flex-1 text-sm text-blue-800">
                    {category.name}
                  </div>
                </div>
              );
          })}

          <a href={`https://blog.feedback-it.com${link}`}>
            <div className="text-xl font-bold azuloscurofeedback">{title}</div>
          </a>

          <div className="text-justify mt-2">
            <p>{removeTagsInString(excerpt)}</p>
            <div className="flex w-full justify-center">
              <a href={`https://blog.feedback-it.com${link}`}>
                <button className="bg-blue-100 text-blue-500 mt-4 block rounded p-2 text-sm">
                  {" "}
                  <span className="">Ver más</span>{" "}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Articulo.propTypes = {
  //   title: PropTypes.string.isRequired,
  //   excerpt: PropTypes.string.isRequired,
  //   categories: PropTypes.string,
  //   link: PropTypes.string,
  //   image: PropTypes.object.isRequired,
  post: PropTypes.object.isRequired,
};

export default Articulo;
