import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';


const CardService = ({ title, description, description2, image, ctaUrl, ctaText}) => {
    return (
        <>
            <div className="w-full md:h-full mt-3 md:mt-0 md:mx-4 rounded-md overflow-hidden md:w-1/2 flex flex-row justify-end bg-white shadow-lg rounded-lg transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110">  
                      
                    <div className="absolute">
                      <img className="w-12 h-12 lg:w-16 lg:h-16 mt-5 mr-5 rotate-90" src={image} />
                    </div>

                    <div className="flex">
                        <div className="mt-10 mb-6 px-10 max-w-xl flex flex-col justify-between">
                            <h2 className="text-2xl text-gray-800 font-semibold">{title}</h2>
                            <p className="mt-2 text-gray-600">{description}</p>
                            
                            {description2?
                            <p className="mt-2 text-gray-600">{description2}</p>
                            : '' }
                            {ctaUrl ? 
                            <Link to={ctaUrl}>
                                <button className="flex text-blue-600 items-center mt-4 text-gray-800 text-sm uppercase font-semibold rounded hover:underline focus:outline-none hover:text-orange-500">
                                    <span>{ctaText}</span>
                                </button>
                            </Link>
                            : ''
                        }
                        </div>
                    </div>

              </div>
        </>
    );
}

CardService.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    description2: PropTypes.string,
    image: PropTypes.string.isRequired,
    ctaText: PropTypes.string.isRequired,
    ctaUrl: PropTypes.string.isRequired,
};

export default CardService;