// import PropTypes from 'prop-types';
import React from 'react';

// import { graphql, useStaticQuery } from 'gatsby';
// import BackgroundImage from 'gatsby-background-image'

import rubros1 from '../images/rubros1.svg';
import rubros2 from '../images/rubros2.svg';
import rubros3 from '../images/rubros3.svg';
import rubros4 from '../images/rubros4.svg';
import rubros5 from '../images/rubros5.svg';
import rubros6 from '../images/rubros6.svg';



const Rubros = () => {

     // const { imagenFondo } = useStaticQuery(graphql`
     // query {
     //     imagenFondo: file(relativePath: { eq: "nubes.png" }) {
     //         childImageSharp {
     //           fluid(quality: 90, maxWidth: 1920) {
     //             ...GatsbyImageSharpFluid_withWebp
     //           }
     //         }
     //       }
     // }
     // `);

    return (
    <>
            
     <div className="w-full flex justify-center bg-white border-t-2 bg-white py-10">

     <div className="flex flex-col md:w-5/6 justify-center items-center ">

               {/* <h1 className="text-2xl md:text-4xl text-center text-pink-900 uppercase font-black mb-5">sasmos?</h1> */}
               {/* <p className="mx-5 sm:mx-0 text-2xl md:text-3xl text-center azuloscurofeedback font-bold mb-10">Valiosos clientes en múltiples sectores</p> */}
               <h2 className="text-2xl md:text-3xl text-gray-700 text-center font-bold border-solid border-gray-500 border-b-2 mb-10">Valiosos clientes en múltiples sectores</h2>


               <div className="flex flex-row flex-wrap md:flex-no-wrap w-full justify-around items-start md:items-start">

                    {/* <div className="flex flex-row w-full sm:w-1/3 sm:mx-5 lg:mx-0 justify-center items-start"> */}
                         <div className="flex flex-col w-1/2 md:w-full px-1 mb-6 md:mb-0 justify-center items-center">
                              <img className="h-20 mt-2" src={rubros1} />

                              <div className="flex w-full flex-col items-center mt-4">
                                   <ul className="text-gray-700 text-xs text-center">
                                        <li>Consultoras</li>
                                        <li>Estudios Contables</li>
                                        <li>Estudios de Abogados</li>
                                        <li>Estudios de Arquitectos</li>
                                   </ul>
                              </div>
                         </div>

                         {/* Dotted */}
                         {/* <div className="hidden md:block w-2/12 flex flex-col mx-5">
                              <div className="w-full border-b-2 border-dotted border-blue-500 h-10"></div>
                              <div className="w-full h-10"></div>
                         </div> */}

                         <div className="flex flex-col w-1/2 md:w-full px-1 mb-6 md:mb-0 justify-center items-center">
                              <img className="h-20 mt-2" src={rubros2} />
                                   <div className="flex w-full flex-col items-center mt-4">
                                        <ul className="text-gray-700 text-xs text-center">
                                             <li>Servicios Financieros</li>
                                             <li>Seguros</li>
                                             <li>Laboratorios</li>
                                             <li>Textil</li>
                                        </ul>
                                   </div>
                         </div>
                    {/* </div> */}

                     {/* Dotted */}
                     {/* <div className="hidden md:block w-2/12 flex flex-col mx-5">
                              <div className="w-full border-b-2 border-dotted border-blue-500 h-10"></div>
                              <div className="w-full h-10"></div>
                    </div> */}

                    {/* <div className="flex flex-row w-full sm:w-1/3 sm:mx-5 lg:mx-0 justify-center items-start"> */}

                         <div className="flex flex-col w-1/2 md:w-full px-1 mb-6 md:mb-0 justify-center items-center">
                              <img className="h-20 mt-2" src={rubros3} />
                                   <div className="flex w-full flex-col items-center mt-4">
                                        <ul className="text-gray-700 text-xs text-center">
                                             <li>Construcción</li>
                                             <li>Industrias</li>
                                             <li>Energía</li>
                                             <li>Agro</li>
                                        </ul>
                                   </div>
                         </div>

                          {/* Dotted */}
                          {/* <div className="hidden md:block w-2/12 flex flex-col mx-5">
                              <div className="w-full border-b-2 border-dotted border-blue-500 h-10"></div>
                              <div className="w-full h-10"></div>
                         </div> */}

                         <div className="flex flex-col w-1/2 md:w-full px-1 mb-6 md:mb-0 justify-center items-center">
                              <img className="h-20 mt-2" src={rubros4} />
                                   <div className="flex w-full flex-col items-center mt-4">
                                        <ul className="text-gray-700 text-xs text-center">
                                             <li>Educacion</li>
                                             <li>Sector Público</li>
                                             <li>Comercios</li>
                                             <li>Salud</li>
                                        </ul>
                                   </div>
                         </div>
                    {/* </div> */}
                    
                     {/* Dotted */}
                     {/* <div className="hidden md:block w-2/12 flex flex-col mx-5">
                              <div className="w-full border-b-2 border-dotted border-blue-500 h-10"></div>
                              <div className="w-full h-10"></div>
                    </div> */}

                    {/* <div className="flex flex-row w-full sm:w-1/3 sm:mx-5 lg:mx-0 justify-center items-start "> */}
                         <div className="flex flex-col w-1/2 md:w-full px-1 mb-6 md:mb-0 justify-center items-center">
                              <img className="h-20 mt-2" src={rubros5} />
                                   <div className="flex w-full flex-col items-center mt-4">
                                        <ul className="text-gray-700 text-xs text-center">
                                             <li>Logística</li>
                                             <li>Transporte</li>
                                             <li>Turismo</li>
                                             <li>Hotelería</li>
                                        </ul>
                                   </div>
                         </div>

                          {/* Dotted */}
                          {/* <div className="hidden md:block w-2/12 flex flex-col sm:ml-5">
                              <div className="w-full border-b-2 border-dotted border-blue-500 h-10"></div>
                              <div className="w-full h-10"></div>
                         </div> */}

                         <div className="flex flex-col w-1/2 md:w-full px-1 mb-6 md:mb-0 justify-center items-center">
                              <img className="h-20 mt-2" src={rubros6} />
                                   <div className="flex w-full flex-col items-center mt-4">
                                        <ul className="text-gray-700 text-xs text-center">
                                             <li>Tecnología</li>
                                             <li>Entretenimiento</li>
                                             <li>Medios</li>
                                             <li>Telecomunicaciones</li>
                                        </ul>
                                   </div>
                         </div>
                    {/* </div> */}

          
          </div>

     </div>
     </div>
















     {/* <div className="bg-white pt-16 pb-8 w-full"> */}
        {/* <div className="bg-white pt-16 pb-8 flex flex-col w-full items-center justify-center border-2"> */}
        {/* <BackgroundImage Tag="div" 
                           className={'bg-gray-300 pt-16 pb-8 flex flex-col w-full items-center justify-center'}
                         //   className={'w-full flex-row justify-center pt-8 pb-16 bg-gray-300'}
                           fluid={imagenFondo.childImageSharp.fluid}
                           style={{backgroundPosition: 'bottom'}}
                        //    backgroundColor={`#040e18`}
          >

            <span className="font-bold text-xl md:text-2xl px-6 uppercase text-blue-800 text-center">Valiosos clientes en múltiples sectores</span>
            <div className="border w-1/6 border-t-1 border-gray-400"></div>

                    <div className="w-full md:w-5/6 mt-3 flex flex-wrap justify-center items-start">
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5 ">
                            <img className="md:h-10 h-12 m-auto" src={dollarsign} />
                            <p className="text-center text-blue-700 mt-2 font-bold">Servicios Financieros</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={bookopen} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Educación</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={activity} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Salud y Laboratorios</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={usercheck} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Seguros</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={zap} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Energía</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={construccion} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Construcción</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={tecnologia} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Telecomunicación y Medios</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={industrias} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Industrias</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={Agropecuario} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Agropecuario</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={turismo} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Turismo</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={contabilidad} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Estudios Contables</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={abogado} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Estudios de Abogados</p>
                       </div>  
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={logistica} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Logística</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={entretenimiento} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Entretenimiento</p>
                       </div>  
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={indumentaria} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Indumentaria</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={comercio} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Comercios</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={sectorpublico} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Sector público</p>
                       </div>
                       <div className="flex flex-col w-1/3 sm:w-1/6 mt-5">
                            <img className="md:h-10 h-12 m-auto" src={arquitectura} />
                            <p className="text-center text-blue-700 mt-3 font-bold">Arquitectura</p>
                       </div>          
                    </div>

          </BackgroundImage> */}
            {/* </div> */}
          {/* </div> */}


    </>);
}

// Valores.propTypes = {
   
// }

export default Rubros;