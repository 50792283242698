import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';

const CardIndex = ({ title, description, cta, ctaUrl, img }) => {
    const ctaPosition = 'justify-center';

    return (<>

        <div className="w-full md:w-1/2 lg:w-1/3 p-6 flex flex-col flex-grow mb-10 lg:mb-0 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                <div 
                 data-sal="zoom-in"
                 data-sal-delay="100"
                 data-sal-duration="700"
                 data-sal-easing="ease"
                 className="flex flex-col flex-grow justify-start bg-white rounded-t rounded-b-none shadow-lg ">

                    <div className="flex justify-center md:justify-center mt-0 pb-0">
                        <img src={img}
                            className="w-16 h-16"
                        />

                    </div>
                    <div className="flex flex-wrap no-underline hover:no-underline mt-3">
                        <div className="w-full font-bold text-center text-lg text-gray-800 px-6 uppercase">{title}</div>
                        <p className="text-gray-600 text-sm px-6 pt-4 mb-5 text-center">
                            {description}
                        </p>
                    </div>
                    {cta ? 
                    <div className={`self-center flex items-center ${ctaPosition}`}>
                        <Link to={ctaUrl ? ctaUrl : '#'}>
                            <button className="mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">{cta}</button>
                        </Link>
                    </div>
                    : ''
                    }
                </div>
        </div>
    </>);
}

CardIndex.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    cta: PropTypes.string,
    ctaUrl: PropTypes.string,
    img: PropTypes.string.isRequired,
}

export default CardIndex;