// import PropTypes from 'prop-types';
import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Articulo from "./Articulo";

const NoticiasIndex = () => {
  const { fetchedPosts } = useStaticQuery(graphql`
    query {
      fetchedPosts: allWpPost(limit: 3, sort: {fields: date, order: DESC}, filter: {categories: {nodes: {elemMatch: {name: {eq: "Destacada"}}}}}) {

        nodes {
          link
          excerpt
          title
          id
          categories {
            nodes {
              id
              name
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                }
              }
            }
          }
        }
      }
    }
  `);

  const posts = fetchedPosts.nodes;
  return (
    <>
      <section className="text-gray-700 bg-gray-100 border-2">
        <div className="container px-5 py-12 mx-auto">
          <div className="flex flex-wrap w-full mb-10 flex-col items-center text-center">
            <h2 className="text-3xl text-gray-700 text-center md:text-4xl font-bold border-solid border-gray-500 border-b-2">
              Últimas noticias
            </h2>

          </div>
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
      
          {posts.map((post) => (
              <Articulo
              key={post.id}
              post={post}

              />
            ))}

          </div>
        </div>
      </section>
    </>
  );
};

// Valores.propTypes = {

// }

export default NoticiasIndex;
