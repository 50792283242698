import React from 'react';

import paso1digitales from '../images/paso1digitales.svg';
import paso2digitales from '../images/paso2digitales.svg';
import paso3digitales from '../images/paso3digitales.svg';

const Comotrabajamosdigitales = () => {


    return (
    <>
            <div className="w-full flex justify-center bg-white border-2 py-10">

                <div className="flex flex-col md:w-5/6 justify-center items-center ">

                <h1 className="text-2xl md:text-3xl text-center text-pink-900 uppercase font-black mb-5">¿Cómo trabajamos?</h1>
                {/* <p className="text-xl text-pink-700 font-bold mb-10">Así lo hacemos...</p> */}

                <div className="flex flex-col md:flex-row w-full justify-around items-center md:items-center">

                    <div className="flex flex-col w-full md:w-1/3 mb-6 md:mb-0 justify-center items-center mx-10 md:flex-col-reverse">
                    <img className="h-40 mt-2 transition duration-500 ease-in-out transform hover:scale-105" src={paso1digitales} />
                    {/* Dotted */}
                    <div className="w-full">
                        <div className="w-1/2 border-r-2 border-dotted border-orange-500 h-16"></div>
                        <div className="w-1/2"></div>
                    </div>
                    <div>
                        <div className="text-2xl font-bold naranjafeedback text-center">Analizamos</div>
                        <p className="text-gray-700 text-center">Estudiamos su necesidad, evaluamos qué hacer y cómo hacerlo, planificamos, dimensionamos, medimos viabilidad, riesgos y factores de éxito, proyectamos, diseñamos procedimientos y cotizamos.</p>
                    </div>
                    </div>

                    <div className="flex flex-col w-full md:w-1/3 mb-6 md:mb-0 justify-center items-center mx-10 ">
                    <img className="h-40 mb-2 transition duration-500 ease-in-out transform hover:scale-105" src={paso2digitales} />
                    {/* Dotted */}
                    <div className="w-full">
                        <div className="w-1/2 border-r-2 border-dotted border-pink-500 h-16"></div>
                        <div className="w-1/2"></div>
                    </div>
                    <div>
                        <div className="text-2xl font-bold text-center purpurafeedback text-center">Ejecutamos</div>
                        <p className="text-gray-700 text-center">Implementamos lo diseñado en el paso previo. Un equipo de especialistas llevará adelante el despliegue según la planificación, documentando procedimientos hasta el cierre, asegurando el cumplimiento de todas las metas definidas.</p>
                    </div>
                    </div>

                    <div className="flex flex-col w-full md:w-1/3 mb-6 md:mb-0 justify-center items-center mx-10 md:flex-col-reverse">
                    <img className="h-40 mt-2 transition duration-500 ease-in-out transform hover:scale-105" src={paso3digitales} />
                        {/* Dotted */}
                    <div className="w-full">
                        <div className="w-1/2 border-r-2 border-dotted border-blue-500 h-16"></div>
                        <div className="w-1/2"></div>
                    </div>
                    <div>
                        <div className="text-2xl font-bold azulclarofeedback text-center">Validamos</div>
                        <p className="text-gray-700 text-center">Un ejecutivo de cuentas se encargará de verificar los resultados junto a usted y sus colaboradores, asegurando su conformidad y garantizando la calidad del trabajo realizado.</p>
                    </div>
                    </div>

                    </div>

                </div>
            </div>
                
    </>);
}

// Comotrabajamosdigitales.propTypes = {
   
// }

export default Comotrabajamosdigitales;