import React from 'react';
// import Image from 'gatsby-image';

import { isMobile } from 'react-device-detect';

import { graphql, useStaticQuery } from 'gatsby';
import Carousel from 'react-elastic-carousel';

import TestimonialCard from '../components/TestimonialCard';
import CardService from '../components/CardService';
import CardIndex from '../components/CardIndex';
import NoticiasIndex from '../components/NoticiasIndex';

import Layout from '../components/layout';
import Hero from '../components/Hero';
import SectionDivisor from '../components/SectionDivisor';
import ContainerSection from '../components/ContainerSection';
import HeroCta from '../components/HeroCta';
import Brands from '../components/Brands';
import Valores from '../components/Valores';
import Rubros from '../components/Rubros';
import Comotrabajamosfeedback from '../components/Comotrabajamosfeedback';

import SectionTitle from '../components/SectionTitle';
import FeatureWithImage from '../components/FeatureWithImage';

import imageHero from '../images/equipoindex.svg';

import AbonoPymesImage from '../images/quehacemos2.svg';
import PorqueNosotros from '../images/porque_nosotros.svg';

import ServiciosDigitales from '../images/serviciosdigitales.svg';
import SolucionesIt from '../images/soluciones-it.svg';

import EquipoExpertos from '../images/valores/equipo-expertos.svg';
import TiemposRespuesta from '../images/valores/tiempos-respuesta.svg';
import IntegradorIndependiente from '../images/valores/integrador-independiente.svg';
import NivelSatisfaccion from '../images/valores/nivel-satisfaccion.svg';
import DiversidadClientes from '../images/valores/diversidad-clientes.svg';
import RelacionesLargoPlazo from '../images/valores/relaciones-largo-plazo.svg';
import DocumentacionImg from '../images/valores/documentacion.svg';
import MetodologiaImg from '../images/valores/metodologia.svg';
import MonitoreoProactivo from '../images/valores/monitoreo-proactivo.svg';
import InformesEjecutivos from '../images/valores/informes-ejecutivos.svg';

function IndexPage() {
  const { image1, image2, image3, image4, image5, image6, clientes, partners } = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "roberto_cardalda.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image2: file(relativePath: { eq: "maria_foto.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image3: file(relativePath: { eq: "martinlopez.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image4: file(relativePath: { eq: "mercedesevangelina.jpeg" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image5: file(relativePath: { eq: "gerardofernandez.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image6: file(relativePath: { eq: "juangaleano.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      clientes: allFile(
        filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" }, relativeDirectory: { eq: "clientes" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      rubros: file(relativePath: { eq: "rubros.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      partners: allFile(
        filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" }, relativeDirectory: { eq: "partners" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const clientespng = clientes.edges.map(img => img.node.childImageSharp.fluid.src);
  clientespng.sort();

  const partnersImg = partners.edges.map(img => img.node.childImageSharp.fluid.src);
  partnersImg.sort();

  let itemsQuantity = isMobile ? 1 : 2;

  return (
    <Layout>
      <Hero
        // title={'CONSULTORIA IT'}
        // subtitle={'Un equipo de expertos a tu disposición'}
        // header={'Abonos de mantenimiento para empresas'}
        title={'SOLUCIONES TECNOLÓGICAS'}
        subtitle={'Tu crecimiento, nuestra misión'}
        header={'Llevamos tu organización al siguiente nivel'}
        image={imageHero}
        cta={'HABLAR CON UN EXPERTO'}
        ctaUrl={'/contacto'}
      />

      <SectionDivisor type={1} />

      <ContainerSection type={6}>
        <SectionTitle title="¿Qué hacemos?" classTitle="mt-16" />

        <FeatureWithImage
          title={'Hacemos que las cosas sucedan'}
          description={
            'Ofrecemos soluciones tecnológicas de alta calidad, adaptables a las necesidades de cada cliente. Trabajamos con profesionalismo y entusiasmo en cada uno de los proyectos, con la experiencia necesaria para el asesoramiento, la implementación y gestión integral de su área de tecnología.'
          }
          description2={
            'Aseguramos procedimientos y metodología innovadora basada en las mejores prácticas, brindando el máximo grado de respaldo y confianza a nuestros clientes.'
          }
          img={AbonoPymesImage}
          classImg={'w-4/6'}
          reverse
        />

        <h2
          data-sal="slide-down"
          data-sal-delay="100"
          data-sal-duration="700"
          data-sal-easing="ease"
          className="text-4xl text-gray-700 mb-5 text-center md:text-4xl font-bold border-solid border-gray-500 border-b-2"
        >
          Servicios
        </h2>

        <div
          data-sal="fade"
          data-sal-delay="100"
          data-sal-duration="700"
          data-sal-easing="ease"
          className="flex flex-col md:flex-row justify-center align-center mb-10 md:-mx-4"
        >
          <CardService
            image={SolucionesIt}
            title={'Soluciones IT'}
            description={
              'Desde soporte a usuarios, administración de infraestructuras complejas hasta la dirección del área de tecnología de diferentes organizaciones.'
            }
            ctaText={'Ver más'}
            ctaUrl={'/soluciones-it'}
          />

          <CardService
            image={ServiciosDigitales}
            title={'Servicios Digitales'}
            description={
              'Hacemos tu idea realidad, optimizamos procesos, analizamos, diseñamos y desarrollamos soluciones a medida.'
            }
            ctaText={'Ver más'}
            ctaUrl={'/servicios-digitales'}
          />
        </div>
      </ContainerSection>

      <Comotrabajamosfeedback />

      <NoticiasIndex />

      <Rubros />

      <Brands
        // title='Algunas organizaciones que han confiado en nosotros'
        logos={clientespng.map(cliente => ({
          classesBrand: 'h-auto lg:h-16',
          img: cliente
        }))}
        bgColorTailwind="bg-gray-100"
        quantityItems={6}
        autoplay
      />

      {/* <div className="flex justify-center bg-gray-100">
        {isMobile ? 
          <Image
          className="w-4/6 object-cover"
          imgStyle={{ objectFit: 'cover' }}
          fluid={rubros.sharp.fluid} 
          />
        :
          <Image
            className="w-full xl:h-auto xl:w-3/6 object-cover"
            imgStyle={{ objectFit: 'cover' }}
            fluid={rubros.sharp.fluid} 
          />
      }
      </div> */}

      <ContainerSection type={6}>
        <h2 className="text-2xl text-gray-700 mt-10 text-center md:text-4xl font-bold border-solid border-gray-500 border-b-2">
          Testimonios
        </h2>

        <Carousel
          autoPlaySpeed={7000}
          showArrows={false}
          // disableArrowsOnEnd={false}
          enableAutoPlay
          pagination={true}
          itemsToShow={itemsQuantity}
          // ref={ref => (this.carousel = ref)}
          // onNextEnd={(currentItem, pageIndex) =>{
          //   alert(JSON.stringify({ currentItem, pageIndex }))
          //   this.goTo(Number(1))
          // }
          // }
          renderPagination={({ pages, activePage, onClick }) => {
            return (
              <div className="flex flex-row justify-around w-1/6">
                {pages.map(page => {
                  const isActivePage = activePage === page;
                  const active = isActivePage;
                  return (
                    <div
                      key={page}
                      onClick={() => onClick(page)}
                      // active={isActivePage}
                      className={`h-4 w-4 rounded-full cursor-pointer shadow-lg ${
                        active ? 'bg-orange-500' : 'bg-blue-700 '
                      }`}
                    />
                  );
                })}
              </div>
            );
          }}
        >
          <TestimonialCard
            image={image4}
            title={'#dedicación'}
            description={
              'Recomiendo trabajar con Feedback dado que es una empresa confiable, dedicada a la solución de problemas, con alto esfuerzo y dedicación hacia el usuario. Busca siempre el cumplimiento de los objetivos estratégicos de los clientes, enfocada en los resultados concretos que generen cambios sustentables. Además, propone soluciones al alcance de cada presupuesto con la posibilidad de ir escalando en nuevas alternativas.'
            }
            author={'Mercedes Evangelina Cano'}
            company={'Ministerio de Producción de la Nación'}
            rol={'Directora de Capacitacion PYME'}
          />
          <TestimonialCard
            image={image3}
            title={'#conocimiento'}
            description={
              'Haber trabajado en varios proyectos e implementaciones con Feedback IT no solo nos aportó un grato acompañamiento en todo lo que se refiere a IT, si no que también nos generó seguridad, conocimiento y empatía en todo sentido. Recomiendo a cada uno de los integrantes de esta empresa por el profesionalismo, la calidad humana y el valor agregado que generan a cada uno de sus proyectos y clientes.'
            }
            author={'Martín Lopez'}
            company={'Louis Dreyfus'}
            rol={'Administrador Regional de Infraestructura'}
          />

          <TestimonialCard
            image={image6}
            title={'#profesionalismo'}
            description={
              'Hemos Descubierto en Feedback IT una empresa  muy profesional con un excelente expertise técnico y gran valor humano,  el cual nos permitió  llevar a cabo  con éxito varios proyectos críticos para nuestro negocio.'
            }
            author={'Juan Galeano'}
            company={'JMI - Paraguay'}
            rol={'Presidente'}
          />

          <TestimonialCard
            image={image1}
            title={'#tranquilidad'}
            description={
              'La predisposición y proactividad de la familia Feedback IT, hacen que podamos trabajar tranquilos, sabiendo que siempre hay alguien que nos respalda en los temas tecnológicos. Nuestro agradecimiento por ser tan gentiles y amables con todo nuestro personal y con nosotros.'
            }
            author={'Roberto D. Cardalda'}
            company={'RC Brokers Asesores de Seguros S.A.'}
            rol={'Presidente'}
          />

          <TestimonialCard
            image={image2}
            title={'#confianza'}
            description={
              'En todos los proyectos que hemos trabajado conjuntamente siempre sentí que lo que buscan es el éxito de su cliente y no simplemente la gestión comercial.'
            }
            author={'Maria Cecilia Martinez Pagola'}
            company={'Universidad de San Andrés'}
            rol={'Directora de TI'}
          />

          <TestimonialCard
            image={image5}
            title={'#pasión'}
            description={
              'Hemos compartido  el placer de trabajar en varios proyectos con Feedback IT, la sinergia lograda para  llevar  a cabo cada proyecto demuestra, no solo la calidad técnica y el profesionalismo del equipo, sino también  su pasión y los detalles  que le impregnan ,  es ese el valor agregado que los distinguen como empresa.'
            }
            author={'Gerardo Fernandez Pacheco'}
            company={'TECNA'}
            rol={'Jefe de Infraestructura de Sistemas'}
          />
        </Carousel>

        <SectionTitle title="¿Porqué elegirnos?" classTitle="mt-16" />

        <FeatureWithImage
          title={'Nosotros'}
          description={
            'Somos un equipo de profesionales con más de 20 años de experiencia brindando soluciones informáticas.'
          }
          description2={
            'Poseemos certificaciones en distintas tecnologías y estamos en constante capacitación para brindarte soluciones que se adapten a las necesidades de tu organización.'
          }
          description3={
            'Crecimos a través de las recomendaciones de nuestros clientes. El profesionalismo, la honestidad y responsabilidad, son factores clave desde nuestros inicios, nos permite contar con un nivel extraordinario de retención de clientes generando vínculos firmes de confianza a largo plazo.'
          }
          img={PorqueNosotros}
        />

        <div data-sal="zoom-in" data-sal-delay="100" data-sal-easing="ease">
          <Valores />
        </div>

        {[
          // {
          //   title: 'Nuestros Valores',
          //   description: 'Focalizamos en el capital humano y contar con un equipo de confianza. Fomentamos valores fundamentales a todos nuestros miembros: EVALUAR QUE PONER',
          //   img: NuestrosValores
          // },
          {
            title: 'Equipo de Expertos',
            description:
              'Contamos con un equipo de valiosos profesionales certificados en las marcas líderes del mercado con sólida trayectoria y experiencia.',
            img: EquipoExpertos
          },
          {
            title: 'Tiempos de Respuesta',
            description:
              'Respondemos con agilidad a cada solicitud, priorizando y gestionando en base a la experiencia y a las mejores prácticas en resolución de incidentes.',
            img: TiemposRespuesta
          },
          {
            title: 'Integrador Independiente',
            description:
              'Conocemos, estudiamos y recomendamos diversidad de marcas, seleccionando la tecnología que mejor se adapte según la necesidad de cada proyecto.',
            img: IntegradorIndependiente
          },
          {
            title: 'Alto Nivel de Satisfacción',
            description:
              'Gran dedicación y esmero para brindar valor agregado en cada trabajo, tiene como resultado un alto nivel de satisfacción de nuestros clientes.',
            img: NivelSatisfaccion
          },
          {
            title: 'Diversidad de Clientes',
            description:
              'Clientes de diferentes países y sectores, que abarcan desde PYMES hasta grandes organizaciones, nos brindan un amplio abanico de recursos, experiencia y conocimiento.',
            img: DiversidadClientes
          },
          {
            title: 'Relaciones a Largo Plazo',
            description:
              'Nos consolidamos como aliados estratégicos de nuestros clientes gracias a comprometernos únicamente en lo que podemos cumplir con alta calidad. Nuestro objetivo es asesorarlos para impulsar su crecimiento.',
            img: RelacionesLargoPlazo
          },
          {
            title: 'Documentación',
            description:
              'Le damos gran trascendencia a la documentación, generando una biblioteca con toda la información relevante de cada cliente, para organizar, optimizar trabajos y facilitar la toma de decisiones.',
            img: DocumentacionImg
          },
          {
            title: 'Metodología',
            description:
              'Planificamos cada proyecto en base a los frameworks y metodologías líderes, evaluando y acotando riesgos, diseñando el mejor procedimiento para asegurar el éxito del trabajo a realizar.',
            img: MetodologiaImg
          },
          {
            title: 'Monitoreo Proactivo',
            description:
              'Monitoreamos la infraestructura de nuestros clientes para anticiparnos a cualquier inconveniente, mitigar riesgos, mejorar la experiencia de los usuarios y resolver con eficiencia.',
            img: MonitoreoProactivo
          },
          {
            title: 'Informes ejecutivos',
            description:
              'Confeccionamos resúmenes ejecutivos detallados y de clara comprensión. Aportamos información relevante para la toma de decisiones, brindando visibilidad y certeza sobre los proyectos realizados y a realizar. Permiten entender el nivel de avance de la infraestructura y definir conjuntamente con el cliente, las prioridades de ejecución, tiempos de finalización, recursos requeridos, riesgos y desvíos que puedan surgir.',
            img: InformesEjecutivos
          }
        ].map(card => (
          <CardIndex key={card.title} title={card.title} description={card.description} img={card.img} />
        ))}
      </ContainerSection>

      <Brands
        title="PARTNERS"
        logos={partnersImg.map(partner => ({
          classesBrand: 'h-auto lg:h-16',
          img: partner
        }))}
        bgColorTailwind="bg-white"
        quantityItems={6}
        autoplay
      />

      <SectionDivisor color1={'#FFFFFF'} color2={'#FFFFFF'} type={2} />

      <HeroCta
        cta={'Potenciemos tu crecimiento!'}
        // message={''}
        actionText={'CONTACTAR'}
        url={'/contacto#contactForm'}
      />
    </Layout>
  );
}

export default IndexPage;
