import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';


const TestimonialCard = ({ title, description, author, image, company, rol}) => {

return (
        <>
            <div className="w-full bg-white shadow-lg rounded-lg py-4 px-8 mx-3 my-10 mt-20">
                <div className="flex justify-center md:justify-end -mt-16">
                    <Image
                        className="w-20 h-20 object-cover rounded-full border-2 border-blue-600"
                        imgStyle={{ objectFit: 'cover' }}
                        fluid={image.sharp.fluid} />
                </div>
                <div>
                    <h2 className="text-blue-400 text-xl font-semibold">{title}</h2>
                    <p className="mt-2 text-gray-600">{description}</p>
                </div>
                <div className="flex justify-end mt-4">
                        <p className="text-md text-blue-700">{company}</p>
                </div>
                <div className="flex justify-end">
                        <p className="text-sm text-blue-500">{author}</p>
                </div>
                <div className="flex justify-end font-bold">
                        <p className="text-sm text-blue-500">{rol}</p>
                </div>
            </div>
        </>
    );
}

TestimonialCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    company: PropTypes.string.isRequired,
    rol: PropTypes.string.isRequired
};

export default TestimonialCard;