// import PropTypes from 'prop-types';
import React from 'react';
// import { Link } from 'gatsby';
import NuestrosValores from '../images/valores/nuestros-valores.svg';


const Valores = () => {


    return (
    <>

                <div className="my-10 py-5 w-full sm:w-5/6 flex flex-col sm:w-full md:w-full lg:flex-row justify-center shadow-lg transition duration-500 ease-in-out transform hover:-translate-y-5 hover:scale-100">

                        <div className="flex flex-col justify-center w-full lg:w-2/6 mb-5 lg:mr-5">
                            <img src={NuestrosValores} className="h-16 mb-5" />
                            <div className="w-full text-center flex flex-col justify-center items-center lg:flex-row text-blue-800 ">
                                <span className="font-bold text-2xl px-6 uppercase ">Nuestros Valores</span>
                            </div>                             
                        </div>

                        <div className="flex flex-col justify-center items-center sm:w-3/6 px-5 md:px-0 lg:w-4/6 mb-5 lg:mr-5 text-blue-800">
                            <p className="text-lg md:text-md md:mx-16 lg:mt-0 text-center md:text-start">
                                {/* Focalizamos en el capital humano y contar con un equipo de confianza. */}
                                {/* Hacemos foco en el capital humano y en contar con un equipo de confianza */}
                                Ejercemos y fomentamos las siguientes cualidades a nuestro equipo de trabajo y colaboradores:
                            </p>
                            {/* <p className="text-lg md:text-md md:mx-16 lg:mt-4 text-center md:text-start">
                                Fomentamos valores fundamentales a todos nuestros miembros:
                            </p> */}
                        </div>

                    <div className={`flex flex-col md:flex-row justify-between items-center text-blue-400 w-full sm:w-5/6`}>
                        <ul className="text-lg md:text-md list-disc w-3/6">
                            <li>Ética</li>
                            <li>Honestidad</li>
                            <li>Integridad</li>
                            <li>Respeto</li>
                            <li>Trabajo en equipo</li>
                        </ul>

                        <ul className="text-lg md:text-md list-disc w-3/6">
                            <li>Pasión</li>
                            <li>Compromiso</li>
                            <li>Creatividad</li>
                            <li>Profesionalismo</li>
                            <li>Retroalimentación continua</li>
                        </ul>
                   
                    </div>
                </div>
    </>);
}

// Valores.propTypes = {
   
// }

export default Valores;